import axios from "axios";
import { toast } from "react-toastify";
import { parse, stringify } from "qs";

import store from "../Redux/store";
import { updateApplicationToken } from "../Redux/Reducers/UserReducer";
import { SERVER_URL } from "../constants/serverRoutes";
import browserRoute from "../constants/browserRoutes";
import { users } from "../actions";

import {
  refreshMsalToken,
  refreshToken,
} from "../components/common/Utils/utils";

const verifyUser = (confirmCode_screen_info, code) => {
  users
    .verifyUser({
      email: confirmCode_screen_info.email,
      verificationCode: confirmCode_screen_info.id,
      code: code,
    })
    .then((response) => {
      const { data: result } = response;
      if (
        result.success === true &&
        result.data !== "" &&
        result.data !== null
      ) {
        let data = response.data?.data;
        store.dispatch(updateApplicationToken(data?.AccessToken));
      }
    })
    .catch((newerror) => {
      return false;
    });
};

const instance = axios.create({
  baseURL: SERVER_URL,
  // timeout: 120000, // Let's say you want to wait at least (120000 ms) === 2 minutes
  timeout: 0, // Set timeout to 0 for no timeout
  paramsSerializer: {
    encode: parse,
    serialize: stringify,
  },
});

instance.interceptors.request.use((x) => {
  const {
    applicationToken,
    domainID,
    subscriptionID,
    // userToken,
    // confirmCode_screen_info,
    // code,
  } = store.getState().pReducers.user;

  x.headers.Authorization = `Bearer ${applicationToken}`;
  x.headers["X-Version"] = "1.0";
  x.headers[!domainID ? "verification-api" : "X-Domain"] = domainID;
  x.headers["X-Subscription"] = subscriptionID;
  x.headers["TimeZoneHeader"] =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  // x.headers["userToken"] = userToken;
  // x.headers["Access-Control-Allow-Origin"] = "*";
  // x.headers["Access-Control-Allow-Credentials"] = "true";
  // x.headers["Content-Type"] = "application/json'";

  x.meta = x.meta || {};
  x.meta.requestStartedAt = new Date().getTime();
  return x;
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // console.log("error--->>", error);

    // Inside your Axios interceptor
    if (
      error?.response?.data?.message?.includes(
        "The server was not found or was not accessible"
      )
    ) {
      window.location.href = browserRoute?.SYSTEM_MAINTENANCE;
    }

    if (error?.code !== "ERR_NETWORK") {
      // console.log("error__11--->>", error);

      if (error?.response) {
        if (error?.response?.status === 400) {
          // console.log("error_if--->>", error?.response?.status);
          // do nothing
          // toast.error(
          //   `${error?.response?.status} - ${error?.response?.statusText}`,
          //   {
          //     position: toast.POSITION.TOP_CENTER,
          //   }
          // );
        } else if (
          error?.response?.status !== 401 &&
          error?.response?.status !== 404
        ) {
          toast.error(
            `${error?.response?.status} - ${error?.response?.statusText}`,
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        }
        const { loginType } = store.getState().pReducers.user;
        if (error?.response?.status === 401) {
          if (loginType === "Local") {
            refreshToken();
          } else {
            refreshMsalToken();
          }
        }
      }

      if (error?.response?.status === 401) {
        // console.log("error__22--->>", error);

        const isRememberChecked = store.getState().pReducers.user.rememberMe;

        const { confirmCode_screen_info, code, loginType, applicationToken } =
          store.getState().pReducers.user;
        if (
          typeof isRememberChecked !== "undefined" &&
          typeof isRememberChecked !== "object" &&
          isRememberChecked !== "false" &&
          isRememberChecked !== "null"
        ) {
          if (loginType === "Local") {
            refreshToken();
          } else {
            refreshMsalToken();
          }

          // axios.get(serverRoutes.APPLICATION_TOKEN).then((res) => {
          //   const { data: response } = res;
          //   const { data } = response;
          //   const token = data.accessToken;
          //   store.dispatch(updateApplicationToken(token));
          //   // setTimeout(() => {
          //   //   // window.location.reload();
          //   // }, [800]);
          // });
        } else {
          // console.log("error__88--->>");
          // store.dispatch(updateApplicationToken(""));
          // window.location.href = "/login";
        }
      }
      return Promise.reject(error);
    } else {
      // console.log("<><><><><><><> Network error ---->> ");
      if (error && error?.code == "ERR_NETWORK") {
        window.location.href = browserRoute?.SYSTEM_MAINTENANCE;
      }
      // console.log(" Network error __99--->>");
    }
  }
);
export default instance;
