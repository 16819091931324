import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
// import DataSource from "../../../actions/dataSource";
import { serverRoutes } from "../../../constants/serverRoutes";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";

import {
  setListingState,
  resetListingState,
} from "../filters/claimManagerFilters";
import {
  ErrorToast,
  commonPaginationForPost,
  getCurrentClientId,
  getMultiSelectFilterIdForApi_ForId,
  getMultiSelectFilterLabelSpitOnlyNames,
  getMultiSelectFilterValueForApi,
  getMultiSelectFilterValueIntoString,
  numberFormatIntoShortForm,
  roundToTwo,
} from "../../../components/common/Utils/utils";
import { getLegendsExtraShortName } from "./../../../components/common/Utils/utils";
import { getClaimAllFiltersData_Call } from "../DenialDetails/claimAllFiltersSlice";
import { gettingSmartFilterForOncologyDenial_Call } from "../OncologyDashboard/OncologyDashboardKpisSlice";
import store from "../../store";

export const RARCSlice = createSlice({
  name: "RARCSlice",
  initialState: {
    rarcOutputList: [],
    carcOutputList: [],
    rarcTreeList: [],
    carcTreeList: [],
    payerFilterArr: [],
    loading: false,

    payerCPTData: [],
    payerCPTTreeList: [],
  },
  reducers: {
    gettingRARCDashboard: (state, action) => {
      state.loading = true;
    },
    successRARCDashboard: (state, action) => {
      const data = action?.payload;
      // console.log(">> ", data);
      if (data?.getRARCDashboardData) {
        const arr = [];
        data?.getRARCDashboardData?.map((item) => {
          const itemName = item?.claimPaymentRemarkCodes
            ? item?.claimPaymentRemarkCodes
            : "Other";
          if (itemName !== "Other") {
            const obj = {
              ...item,
              name: `${itemName} - $${numberFormatIntoShortForm(
                item?.sumAmount ? item?.sumAmount : "0"
              )}`,
              // name: itemName,
              value: item?.sumAmount,
            };
            arr.push(obj);
          }
        });
        const treeObj = {
          name: "root",
          children: arr,
        };
        state.rarcTreeList = treeObj;
        const sortedOnAmount = data?.getRARCDashboardData.sort(
          (a, b) => b.sumAmount - a.sumAmount
        );
        state.rarcOutputList = sortedOnAmount?.slice(0, 10);
      } else {
        state.rarcOutputList = [];
        state.rarcTreeList = [];
      }
      if (data?.payerFilterData) {
        state.payerFilterArr = data?.payerFilterData?.map((item) => {
          return {
            label: `${item?.payerName}${
              item?.payerPrimaryId ? `-${item?.payerPrimaryId}` : ""
            }`,
            // label: item?.payerName,
            value: item?.payerId,
          };
        });
      } else {
        state.payerFilterArr = [];
      }
      state.loading = false;
    },
    successCARCDashboard: (state, action) => {
      const data = action?.payload;
      // console.log(">> 123123 > ", data?.getRARCDashboardData);
      if (data?.getRARCDashboardData) {
        const arr = [];
        data?.getRARCDashboardData?.map((item) => {
          const itemName = item?.groupAndReasonCode
            ? item?.groupAndReasonCode
            : "Other";
          if (itemName !== "Other") {
            const obj = {
              ...item,
              name: `${itemName} - $${numberFormatIntoShortForm(
                item?.sumAmount ? item?.sumAmount : "0"
              )}`,
              // name: itemName,
              value: item?.sumAmount,
            };
            arr.push(obj);
          }
        });
        const treeObj = {
          name: "root",
          children: arr,
        };
        state.carcTreeList = treeObj;
        const sortedOnAmount = data?.getRARCDashboardData.sort(
          (a, b) => b.sumAmount - a.sumAmount
        );
        state.carcOutputList = sortedOnAmount?.slice(0, 10);
      } else {
        state.carcOutputList = [];
        state.carcTreeList = [];
      }

      state.loading = false;
    },
    failedRARCDashboard: (state, action) => {
      state.loading = false;
    },

    successPayerCPTDashboard: (state, action) => {
      const data = action?.payload;
      // console.log("123123 > ", data?.getCPTDashboardData);
      if (data?.getCPTDashboardData) {
        const arr = [];
        data?.getCPTDashboardData?.map((item) => {
          const itemName = item?.cptCode ? item?.cptCode : "Other";
          if (itemName !== "Other") {
            const obj = {
              ...item,
              name: `${itemName} - $${numberFormatIntoShortForm(
                item?.sumAmount ? item?.sumAmount : "0"
              )}`,
              // name: itemName,
              value: item?.sumAmount,
            };
            arr.push(obj);
          }
        });
        const treeObj = {
          name: "root",
          children: arr,
        };
        // state.carcTreeList = treeObj;
        const sortedOnAmount = data?.getCPTDashboardData.sort(
          (a, b) => b.sumAmount - a.sumAmount
        );
        state.payerCPTData = sortedOnAmount?.slice(0, 10);
        state.payerCPTTreeList = treeObj;
      } else {
        state.payerCPTData = [];
        state.payerCPTTreeList = [];
      }

      state.loading = false;
    },
    failedPayerCPTDashboard: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  gettingRARCDashboard,
  successRARCDashboard,
  successCARCDashboard,
  failedRARCDashboard,
  successPayerCPTDashboard,
} = RARCSlice.actions;

//get RARC Dashboard Data
// Reason Code Heatmap Dashboard
const groupByRARC = (paramsTemp, params) => async (dispatch, getState) => {
  try {
    commonPaginationForPost(
      getState,
      paramsTemp,
      params,
      dispatch,
      successRARCDashboard,
      "RARCFilter",
      "GET_RARC",
      null,
      "",
      ""
    );
  } catch (e) {
    // console.log("catch--->>", e);
  }
};
const groupByCARC = (paramsTemp, params) => async (dispatch, getState) => {
  try {
    commonPaginationForPost(
      getState,
      paramsTemp,
      params,
      dispatch,
      successCARCDashboard,
      "CARCFilter",
      "GET_CARC",
      null,
      "",
      ""
    );
  } catch (e) {
    // console.log("catch--->>", e);
  }
};
export const gettingRARC_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "RARCFilter",
          ignore: ["claimStatus,payers,frequencyType,search"],
        })
      );

      // ========= below code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - start ==============
      // let advStngServiceLineExecList =
      //   getState().pReducers.user.advStngServiceLineExecList;
      // let data = advStngServiceLineExecList?.split(",")?.map((item) => {
      //   return {
      //     label: item.toString(),
      //     value: item.toString(),
      //   };
      // });
      // dispatch(
      //   setListingState({
      //     listing: "RARCFilter",
      //     value: {
      //       smartFilterArr: data,
      //       pageNumber: 1,
      //     },
      //   })
      // );
      // ========= above code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - end ==============
    }
    let sliceStateUser = getState().pReducers.user;
    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let sliceState = getState().npReducers.filters.RARCFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      // call for smart filters
      dispatch(gettingSmartFilterForOncologyDenial_Call("RARCFilter"));

      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceState?.payerFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payerFilter
          )?.toString(),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueIntoString(
              sliceState?.smartFilterArr,
              "value"
            ),
          }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceState?.payerFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payerFilter
          )?.toString(),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueIntoString(
              sliceState?.smartFilterArr,
              "value"
            ),
          }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      // console.log("params--->>", params);

      dispatch(groupByRARC(paramsTemp, params));
      dispatch(groupByCARC(paramsTemp, params));
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };

const groupByCPT = (paramsTemp, params) => async (dispatch, getState) => {
  try {
    commonPaginationForPost(
      getState,
      paramsTemp,
      params,
      dispatch,
      successPayerCPTDashboard,
      "PayerCPTDashFilter",
      "GET_CPT",
      null,
      "",
      ""
    );
  } catch (e) {
    // console.log("catch--->>", e);
  }
};

export const gettingPayerCPT_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "PayerCPTFilter",
          // ignore: ["claimStatus,payers,frequencyType,search"],
        })
      );

      // ========= below code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - start ==============
      // let advStngServiceLineExecList =
      //   getState().pReducers.user.advStngServiceLineExecList;
      // let data = advStngServiceLineExecList?.split(",")?.map((item) => {
      //   return {
      //     label: item.toString(),
      //     value: item.toString(),
      //   };
      // });
      // dispatch(
      //   setListingState({
      //     listing: "RARCFilter",
      //     value: {
      //       smartFilterArr: data,
      //       pageNumber: 1,
      //     },
      //   })
      // );
      // ========= above code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - end ==============
    }
    let sliceStateUser = getState().pReducers.user;
    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let sliceState = getState().npReducers.filters.PayerCPTFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      // call for smart filters
      dispatch(gettingSmartFilterForOncologyDenial_Call("PayerCPTFilter"));

      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceState?.payerFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payerFilter
          )?.toString(),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueIntoString(
              sliceState?.smartFilterArr,
              "value"
            ),
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceState?.payerFilter?.length > 0 && {
          payerId: getMultiSelectFilterValueForApi(
            sliceState?.payerFilter
          )?.toString(),
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueIntoString(
              sliceState?.smartFilterArr,
              "value"
            ),
          }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      dispatch(groupByCPT(paramsTemp, params));
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };

export default RARCSlice.reducer;
