import React from "react";

const DRElgibilityBotIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_132_14)">
        <path
          d="M3 10.0002C3 9.20458 3.31607 8.44152 3.87867 7.87892C4.44128 7.31631 5.20434 7.00024 5.99998 7.00024H17.9999C18.7956 7.00024 19.5586 7.31631 20.1212 7.87892C20.6838 8.44152 20.9999 9.20458 20.9999 10.0002V16.0002C20.9999 16.7958 20.6838 17.5589 20.1212 18.1215C19.5586 18.6841 18.7956 19.0002 17.9999 19.0002H5.99998C5.20434 19.0002 4.44128 18.6841 3.87867 18.1215C3.31607 17.5589 3 16.7958 3 16.0002V10.0002Z"
          //   stroke="#53A562"
          stroke-width="1"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.9999 4V6.99998"
          //   stroke="#53A562"
          stroke-width="1"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 13V13.0146"
          //   stroke="#53A562"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.9999 13V13.0146"
          //   stroke="#53A562"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export default DRElgibilityBotIcon;
