import React, { useState, useEffect } from "react";

import logoDOW from "../../../assets/svgs/Logo.svg";
import MailIcon from "../../../assets/svgs/MailIcon";
import EyeIcon from "../../../assets/svgs/EyeIcon";
import EyeOffIcon from "../../../assets/svgs/EyeOffIcon";

//================================================

import {
  Box,
  Typography,
  Grid,
  Button,
  FormControlLabel,
  Input,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";

import { NavLink, useNavigate } from "react-router-dom";
import browserRoute from "../../../constants/browserRoutes";
import SessionIcon from "./SessionIcon";

const SessionTimeOut = () => {
  const nav = useNavigate();
  return (
    <div
      className="row d-flex align-items-center justify-content-center new-login-bg"
      style={{ position: "relative", height: "100vh" }}
    >
      <Box className="login-form-side">
        <Grid container spacing={3} className="login-form-side">
          <Box className="login-form-inner" style={{ width: "500px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "0px",
              }}
            >
              <a href="/">
                <img src={logoDOW} alt="DataRovers Logo" width="240px" />
              </a>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "0px",
              }}
            >
              <SessionIcon />
            </Box>

            <h2 style={{ textAlign: "center", marginBottom: "10px" }}>
              Session Timeout
            </h2>
            <p
              style={{
                textAlign: "center",
                fontSize: "15px",
                color: "#494949",
                lineHeight: "18px",
              }}
            >
              You weren't clicking around any more, so we logged you out for
              your protection. To get back in, just sign in again.
            </p>

            <div className="login-form">
              <Grid item xs={12}>
                <Button
                  style={{
                    display: "block",
                    backgroundColor: "#3ca54f",
                    padding: "12px 20px",
                    margin: "20px auto",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    color: "#ffffff",
                    border: "none",
                    width: "100%",
                    height: "42px",
                    borderRadius: "6px",
                    cursor: "pointer",
                    textDecoration: "none",
                    textTransform: "none",
                    maxWidth: "350px",
                  }}
                  variant="contained"
                  type="submit"
                  fullWidth
                  className="login-btn "
                  onClick={() => nav("/")}
                >
                  Login
                </Button>
              </Grid>
            </div>
          </Box>
        </Grid>
        {/* </div> */}
        <Grid
          style={{
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <a
            href="https://datarovers.com/"
            target="_blank"
            style={{
              marginTop: "20px",
              color: "#B3B3B3",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0.4px",
              textAlign: "center",
              margin: "0px",
              whiteSpace: "nowrap",
            }}
          >
            Visit our Website
          </a>
          <span class="DR-line-new-design mt-0 mr-2 line-op-sea"></span>
          <a
            href="https://datarovers.com/terms-and-conditions/"
            target="_blank"
            style={{
              marginTop: "20px",
              color: "#B3B3B3",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0.4px",
              textAlign: "center",
              margin: "0px",
              whiteSpace: "nowrap",
            }}
          >
            Terms of Service
          </a>
          <span class="DR-line-new-design mt-0 mr-2 line-op-sea"></span>
          <a
            href="https://datarovers.com/privacy-policy/"
            target="_blank"
            style={{
              marginTop: "20px",
              color: "#B3B3B3",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0.4px",
              textAlign: "center",
              margin: "0px",
              whiteSpace: "nowrap",
            }}
          >
            Privacy Policy
          </a>
          <span class="DR-line-new-design mt-0 mr-2 line-op-sea"></span>
          <p
            style={{
              marginTop: "20px",
              color: "#B3B3B3",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0.4px",
              textAlign: "center",
              margin: "0px",
              whiteSpace: "nowrap",
            }}
          >
            © Copyright 2024 DataRovers. All rights reserved.
          </p>
        </Grid>
      </Box>
    </div>
  );
};

export default SessionTimeOut;
