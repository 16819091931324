import { createSlice } from "@reduxjs/toolkit";

import {
  commonPaginationWithCatch,
  getMultiSelectFilterValueForApi,
  getMultiSelectFilterValueFor837Payers,
} from "../../../components/common/Utils/utils";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../../constants/serverRoutes";

export const claim837AllFiltersSlice = createSlice({
  name: "claim837AllFiltersSlice",
  initialState: {
    // new filters
    filterLoader: {
      fillingIndicator837: true,
      serviceLine837: true,
      payers837: true,
      provider837: true,
      billingProvider837: true,
      renderingProvider837: true,
      attendingProvider837: true,
      frequencyType837: true,
      diagnoseCode837: true,
      cptCode837: true,
    },

    fillingIndicator837FilterArr: [],

    renderingProvider837FilterArr: [],
    billingProvider837FilterArr: [],
    attendingProvider837FilterArr: [],

    renderingProviderClaimAgingFilterArr: [],
    billingProviderClaimAgingFilteArr: [],
    attendingProviderClaimAgingFilterArr: [],

    serviceLine837FilterArr: [],
    payers837FilterArr: [],
    payersClaimAgingFilterArr: [],
    provider837FilterArr: [],
    frequencyType837FilterArr: [],
    diagnoseCode837FilterArr: [],
    diagnoseCodeClaimAgingFilterArr: [],
    cptCodeClaimAgingFilterArr: [],
    cptCode837FilterArr: [],
  },
  reducers: {
    //Service Line Filters
    gettingServiceLineFilter: (state, action) => {
      state.filterLoader.serviceLine837 = true;
    },
    successServiceLineFilter: (state, action) => {
      const data = action?.payload;
      if (data?.CPTCodeJSON) {
        state.serviceLine837FilterArr = JSON.parse(data?.CPTCodeJSON);
      } else {
        state.serviceLine837FilterArr = [];
      }
      state.filterLoader.serviceLine837 = false;
    },
    failedServiceLineFilter: (state, action) => {
      state.filterLoader.serviceLine837 = false;
    },

    //Payers Filters
    gettingPayerFilter: (state, action) => {
      state.filterLoader.payers837 = true;
    },
    successPayerFilter2ForSearch: (state, action) => {
      const data = action?.payload;
      // console.log("successPayerFilter2ForSearch--->>", data);
      if (data?.listingFilter) {
        // console.log("successPayerFilter2ForSearch__if--->>", data);
        const output = data?.data?.claims;
        if (output?.length) {
          if (data?.data?.selectedPayerList?.length > 0) {
            const excludedValues = data?.data?.selectedPayerList.map(
              (obj) => obj?.name
            );
            const excludedArray = data?.data?.selectedPayerList.map((obj) => {
              let userFriendlyName = obj?.name;

              return {
                value: obj?.id,
                label: userFriendlyName,
              };
            });

            let arrTemp = [];
            let newArr1 = [];
            output?.filter((obj) => {
              if (excludedValues.includes(obj?.name)) {
                arrTemp.push(obj);
              } else {
                let userFriendlyName = obj?.name;
                newArr1.push({
                  value: obj?.id,
                  label: userFriendlyName,
                });
              }
            });

            newArr1.unshift(...excludedArray);

            // state.payers837FilterArr = newArr1;
            handleMultiFilter(state, newArr1, data?.listingFilter, "payers");
          } else {
            let newArr = [];

            output?.forEach((item) => {
              let userFriendlyName = item?.name;
              newArr.push({
                value: item?.id,
                label: userFriendlyName,
              });
            });

            // state.payers837FilterArr = newArr;
            handleMultiFilter(state, newArr, data?.listingFilter, "payers");
          }
        } else {
          // state.payers837FilterArr = [];
          handleMultiFilter(state, [], data?.listingFilter, "payers");
        }

        state.filterLoader.payers837 = false;
      } else {
        // console.log("successPayerFilter2ForSearch__else--->>", data);
        const output = data?.claims;
        if (output?.length) {
          if (data?.selectedPayerList?.length > 0) {
            const excludedValues = data?.selectedPayerList.map(
              (obj) => obj?.name
            );
            const excludedArray = data?.selectedPayerList.map((obj) => {
              let userFriendlyName = obj?.name;

              return {
                value: obj?.id,
                label: userFriendlyName,
              };
            });

            let arrTemp = [];
            let newArr1 = [];
            output?.filter((obj) => {
              if (excludedValues.includes(obj?.id)) {
                arrTemp.push(obj);
              } else {
                let userFriendlyName = obj?.name;
                newArr1.push({
                  value: obj?.id,
                  label: userFriendlyName,
                });
              }
            });

            newArr1.unshift(...excludedArray);

            // state.payers837FilterArr = newArr1;
            handleMultiFilter(state, newArr1, data?.listingFilter, "payers");
          } else {
            let newArr = [];

            output?.forEach((item) => {
              let userFriendlyName = item?.name;
              newArr.push({
                value: item?.id,
                label: userFriendlyName,
              });
            });

            // state.payers837FilterArr = newArr;
            handleMultiFilter(state, newArr, data?.listingFilter, "payers");
          }
        } else {
          // state.payers837FilterArr = [];
          handleMultiFilter(state, [], data?.listingFilter, "payers");
        }

        state.filterLoader.payers837 = false;
      }
    },
    successPayerFilter: (state, action) => {
      const data = action?.payload;
      // console.log("successPayerFilter--->>", data);
      if (data?.listingFilter) {
        // console.log("successPayerFilter__if--->>", data);
        const output = data?.data?.claims;
        if (output?.length) {
          if (data?.data?.selectedPayerList?.length > 0) {
            const excludedValues = data?.data?.selectedPayerList.map(
              (obj) => obj?.id
            );
            const excludedArray = data?.data?.selectedPayerList.map((obj) => {
              let userFriendlyName = obj?.name;

              return {
                value: obj?.id,
                label: userFriendlyName,
              };
            });

            let arrTemp = [];
            let newArr1 = [];
            output?.filter((obj) => {
              if (excludedValues.includes(obj?.id)) {
                arrTemp.push(obj);
              } else {
                let userFriendlyName = obj?.name;
                newArr1.push({
                  value: obj?.id,
                  label: userFriendlyName,
                });
              }
            });

            newArr1.unshift(...excludedArray);

            state.payers837FilterArr = newArr1;
            state.payersClaimAgingFilterArr = newArr1;
          } else {
            let newArr = [];

            output?.forEach((item) => {
              let userFriendlyName = item?.name;
              newArr.push({
                value: item?.id,
                label: userFriendlyName,
              });
            });

            state.payers837FilterArr = newArr;
            state.payersClaimAgingFilterArr = newArr;
          }
        } else {
          state.payers837FilterArr = [];
          state.payersClaimAgingFilterArr = [];
        }

        state.filterLoader.payers837 = false;
      } else {
        // console.log("successPayerFilter__else--->>", data);
        const output = data?.claims;
        if (output?.length) {
          if (data?.selectedPayerList?.length > 0) {
            const excludedValues = data?.selectedPayerList.map(
              (obj) => obj?.id
            );
            const excludedArray = data?.selectedPayerList.map((obj) => {
              let userFriendlyName = obj?.name;

              return {
                value: obj?.id,
                label: userFriendlyName,
              };
            });

            let arrTemp = [];
            let newArr1 = [];
            output?.filter((obj) => {
              if (excludedValues.includes(obj?.name)) {
                arrTemp.push(obj);
              } else {
                let userFriendlyName = obj?.name;
                newArr1.push({
                  value: obj?.id,
                  label: userFriendlyName,
                });
              }
            });

            newArr1.unshift(...excludedArray);

            state.payers837FilterArr = newArr1;
            state.payersClaimAgingFilterArr = newArr1;
          } else {
            let newArr = [];

            output?.forEach((item) => {
              let userFriendlyName = item?.name;
              newArr.push({
                value: item?.id,
                label: userFriendlyName,
              });
            });

            state.payers837FilterArr = newArr;
            state.payersClaimAgingFilterArr = newArr;
          }
        } else {
          state.payers837FilterArr = [];
          state.payersClaimAgingFilterArr = [];
        }

        state.filterLoader.payers837 = false;
      }
    },
    failedPayerFilter: (state, action) => {
      state.filterLoader.payers837 = false;
    },

    //Filling Indicator
    gettingFillingIndicatorFilter: (state, action) => {
      state.filterLoader.fillingIndicator837 = true;
    },
    successFillingIndicatorFilter: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          let userFriendlyName = `${item?.description}`;
          newArr.push({
            value: item?.name,
            label: `${item?.name} - ${userFriendlyName}`,
          });
        });

        state.fillingIndicator837FilterArr = newArr;
      } else {
        state.fillingIndicator837FilterArr = [];
      }
      state.filterLoader.fillingIndicator837 = false;
    },
    failedFillingIndicatorFilter: (state, action) => {
      state.filterLoader.fillingIndicator837 = false;
    },

    //BillingProvider
    gettingBillingProviderFilter: (state, action) => {
      state.filterLoader.billingProvider837 = true;
    },
    successBillingProvider2ForSearch: (state, action) => {
      const data = action?.payload;
      // console.log("successBillingProvider2ForSearch--->>", data);
      try {
        if (data?.listingFilter) {
          // console.log("successBillingProvider2ForSearch__if--->>", data);
          const output = data?.data?.claims;
          const outputSelected = data?.data?.selectedBillingProvidersList;
          if (output?.length) {
            if (data?.data?.selectedBillingProvidersList?.length > 0) {
              const excludedValues =
                data?.data?.selectedBillingProvidersList.map(
                  (obj) => obj?.name
                );

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.name)) {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;
                  arrTemp.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                } else {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;

                  newArr1.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...arrTemp);

              handleMultiFilter(
                state,
                newArr1,
                data?.listingFilter,
                "billingProvider"
              );
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = `${item?.name} - ${item?.npi}`;

                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });

              handleMultiFilter(
                state,
                newArr,
                data?.listingFilter,
                "billingProvider"
              );
            }
          } else {
            let newArr = [];
            if (outputSelected?.length) {
              outputSelected?.forEach((item) => {
                let userFriendlyName = `${item?.name} - ${item?.npi}`;
                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });
            }

            handleMultiFilter(
              state,
              newArr,
              data?.listingFilter,
              "billingProvider"
            );
          }

          state.filterLoader.billingProvider837 = false;
        } else {
          // console.log("successBillingProvider2ForSearch__else--->>", data);
          const output = data?.claims;
          if (output?.length) {
            if (data?.selectedBillingProvidersList?.length > 0) {
              const excludedValues = data?.selectedBillingProvidersList.map(
                (obj) => obj?.name
              );
              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.name)) {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;
                  arrTemp.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                } else {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;

                  newArr1.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...arrTemp);

              handleMultiFilter(
                state,
                newArr1,
                data?.listingFilter,
                "billingProvider"
              );
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = `${item?.name} - ${item?.npi}`;

                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });

              handleMultiFilter(
                state,
                newArr,
                data?.listingFilter,
                "billingProvider"
              );
            }
          } else {
            handleMultiFilter(
              state,
              [],
              data?.listingFilter,
              "billingProvider"
            );
          }

          state.filterLoader.billingProvider837 = false;
        }
      } catch (err) {
        // console.log("err--->>", err);
      }
    },
    successBillingProviderFilter: (state, action) => {
      const data = action?.payload;
      // console.log("successBillingProviderFilter--->>", data);
      try {
        if (data?.listingFilter) {
          // console.log("successBillingProviderFilter__if--->>", data);
          const output = data?.data?.claims;
          if (output?.length) {
            if (data?.data?.selectedBillingProvidersList?.length > 0) {
              const excludedValues =
                data?.data?.selectedBillingProvidersList.map(
                  (obj) => obj?.name
                );

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.name)) {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;
                  arrTemp.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                } else {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;

                  newArr1.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...arrTemp);

              state.billingProvider837FilterArr = newArr1;
              state.billingProviderClaimAgingFilteArr = newArr1;
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = `${item?.name}`;

                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });

              state.billingProvider837FilterArr = newArr;
              state.billingProviderClaimAgingFilteArr = newArr;
            }
          } else {
            state.billingProvider837FilterArr = [];
            state.billingProviderClaimAgingFilteArr = [];
          }

          state.filterLoader.billingProvider837 = false;
        } else {
          // console.log("successBillingProviderFilter__else--->>", data);
          const output = data?.claims;
          if (output?.length) {
            if (data?.selectedBillingProvidersList?.length > 0) {
              const excludedValues = data?.selectedBillingProvidersList.map(
                (obj) => obj?.name
              );

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.name)) {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;
                  arrTemp.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                } else {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;

                  newArr1.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...arrTemp);

              state.billingProvider837FilterArr = newArr1;
              state.billingProviderClaimAgingFilteArr = newArr1;
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = `${item?.name}`;

                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });

              state.billingProvider837FilterArr = newArr;
              state.billingProviderClaimAgingFilteArr = newArr;
            }
          } else {
            state.billingProvider837FilterArr = [];
            state.billingProviderClaimAgingFilteArr = [];
          }

          state.filterLoader.billingProvider837 = false;
        }
      } catch (err) {
        // console.log("e--->>", err);
      }
    },
    failedBillingProviderFilter: (state, action) => {
      state.filterLoader.billingProvider837 = false;
      state.billingProvider837FilterArr = [];
    },

    //RenderingProvider
    gettingRenderingProviderFilter: (state, action) => {
      state.filterLoader.renderingProvider837 = true;
    },
    successRenderingProvider2ForSearch: (state, action) => {
      const data = action?.payload;
      // console.log("successRenderingProvider2ForSearch--->>", data);
      try {
        if (data?.listingFilter) {
          // console.log("successRenderingProvider2ForSearch__if--->>", data);
          const output = data?.data?.claims;
          const outputSelected = data?.data?.selectedRenderingProvidersList;
          if (output?.length) {
            if (data?.data?.selectedRenderingProvidersList?.length > 0) {
              const excludedValues =
                data?.data?.selectedRenderingProvidersList.map(
                  (obj) => obj?.name
                );

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.name)) {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;
                  arrTemp.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                } else {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;

                  newArr1.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...arrTemp);

              handleMultiFilter(
                state,
                newArr1,
                data?.listingFilter,
                "renderingProvider"
              );
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = `${item?.name} - ${item?.npi}`;

                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });

              handleMultiFilter(
                state,
                newArr,
                data?.listingFilter,
                "renderingProvider"
              );
            }
          } else {
            let newArr = [];
            if (outputSelected?.length) {
              outputSelected?.forEach((item) => {
                let userFriendlyName = `${item?.name} - ${item?.npi}`;
                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });
            }

            handleMultiFilter(
              state,
              newArr,
              data?.listingFilter,
              "renderingProvider"
            );
          }

          state.filterLoader.renderingProvider837 = false;
        } else {
          // console.log("successRenderingProvider2ForSearch__else--->>", data);
          const output = data?.claims;
          if (output?.length) {
            if (data?.selectedRenderingProvidersList?.length > 0) {
              const excludedValues = data?.selectedRenderingProvidersList.map(
                (obj) => obj?.name
              );

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.name)) {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;
                  arrTemp.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                } else {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;

                  newArr1.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...arrTemp);

              handleMultiFilter(
                state,
                newArr1,
                data?.listingFilter,
                "renderingProvider"
              );
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = `${item?.name} - ${item?.npi}`;

                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });

              handleMultiFilter(
                state,
                newArr,
                data?.listingFilter,
                "renderingProvider"
              );
            }
          } else {
            handleMultiFilter(
              state,
              [],
              data?.listingFilter,
              "renderingProvider"
            );
          }

          state.filterLoader.renderingProvider837 = false;
        }
      } catch (err) {
        // console.log("err--->>", err);
      }
    },
    successRenderingProviderFilter: (state, action) => {
      const data = action?.payload;
      // console.log("successRenderingProviderFilter--->>", data);
      try {
        if (data?.listingFilter) {
          // console.log("successRenderingProviderFilter__if--->>", data);
          const output = data?.data?.claims;
          if (output?.length) {
            if (data?.data?.selectedBillingProvidersList?.length > 0) {
              const excludedValues =
                data?.data?.selectedBillingProvidersList.map(
                  (obj) => obj?.name
                );

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.name)) {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;
                  arrTemp.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                } else {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;

                  newArr1.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...arrTemp);

              state.renderingProvider837FilterArr = newArr1;
              state.renderingProviderClaimAgingFilterArr = newArr1;
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = `${item?.name} - ${item?.npi}`;

                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });

              state.renderingProvider837FilterArr = newArr;
              state.renderingProviderClaimAgingFilterArr = newArr;
            }
          } else {
            state.renderingProvider837FilterArr = [];
            state.renderingProviderClaimAgingFilterArr = [];
          }

          state.filterLoader.renderingProvider837 = false;
        } else {
          // console.log("successRenderingProviderFilter__else--->>", data);
          const output = data?.claims;
          if (output?.length) {
            if (data?.selectedBillingProvidersList?.length > 0) {
              const excludedValues = data?.selectedBillingProvidersList.map(
                (obj) => obj?.name
              );

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.name)) {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;
                  arrTemp.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                } else {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;

                  newArr1.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...arrTemp);

              state.renderingProvider837FilterArr = newArr1;
              state.renderingProviderClaimAgingFilterArr = newArr1;
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = `${item?.name}`;

                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });

              state.renderingProvider837FilterArr = newArr;
              state.renderingProviderClaimAgingFilterArr = newArr;
            }
          } else {
            state.renderingProvider837FilterArr = [];
            state.renderingProviderClaimAgingFilterArr = [];
          }

          state.filterLoader.renderingProvider837 = false;
        }
      } catch (err) {
        // console.log("e--->>", err);
      }
    },
    failedRenderingProviderFilter: (state, action) => {
      state.filterLoader.renderingProvider837 = false;
      state.renderingProvider837FilterArr = [];
    },

    //AttendingProvider
    gettingAttendingProviderFilter: (state, action) => {
      state.filterLoader.attendingProvider837 = true;
    },
    successAttendingProvider2ForSearch: (state, action) => {
      const data = action?.payload;
      // console.log("successRenderingProvider2ForSearch--->>", data);
      try {
        if (data?.listingFilter) {
          // console.log("successRenderingProvider2ForSearch__if--->>", data);
          const output = data?.data?.claims;
          const outputSelected = data?.data?.selectedAttendingProvidersList;
          if (output?.length) {
            if (data?.data?.selectedAttendingProvidersList?.length > 0) {
              const excludedValues =
                data?.data?.selectedAttendingProvidersList.map(
                  (obj) => obj?.name
                );

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.name)) {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;
                  arrTemp.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                } else {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;

                  newArr1.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...arrTemp);

              handleMultiFilter(
                state,
                newArr1,
                data?.listingFilter,
                "attendingProvider"
              );
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = `${item?.name} - ${item?.npi}`;

                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });

              handleMultiFilter(
                state,
                newArr,
                data?.listingFilter,
                "attendingProvider"
              );
            }
          } else {
            let newArr = [];
            if (outputSelected?.length) {
              outputSelected?.forEach((item) => {
                let userFriendlyName = `${item?.name} - ${item?.npi}`;
                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });
            }

            handleMultiFilter(
              state,
              newArr,
              data?.listingFilter,
              "attendingProvider"
            );
          }

          state.filterLoader.attendingProvider837 = false;
        } else {
          // console.log("successRenderingProvider2ForSearch__else--->>", data);
          const output = data?.claims;
          if (output?.length) {
            if (data?.selectedAttendingProvidersList?.length > 0) {
              const excludedValues = data?.selectedAttendingProvidersList.map(
                (obj) => obj?.name
              );

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.name)) {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;
                  arrTemp.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                } else {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;

                  newArr1.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...arrTemp);

              handleMultiFilter(
                state,
                newArr1,
                data?.listingFilter,
                "attendingProvider"
              );
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = `${item?.name} - ${item?.npi}`;

                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });

              handleMultiFilter(
                state,
                newArr,
                data?.listingFilter,
                "attendingProvider"
              );
            }
          } else {
            handleMultiFilter(
              state,
              [],
              data?.listingFilter,
              "attendingProvider"
            );
          }

          state.filterLoader.attendingProvider837 = false;
        }
      } catch (err) {
        // console.log("err--->>", err);
      }
    },
    successAttendingProviderFilter: (state, action) => {
      const data = action?.payload;
      // console.log("successRenderingProviderFilter--->>", data);
      try {
        if (data?.listingFilter) {
          // console.log("successRenderingProviderFilter__if--->>", data);
          const output = data?.data?.claims;
          if (output?.length) {
            if (data?.data?.selectedAttendingProvidersList?.length > 0) {
              const excludedValues =
                data?.data?.selectedAttendingProvidersList.map(
                  (obj) => obj?.name
                );

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.name)) {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;
                  arrTemp.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                } else {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;

                  newArr1.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...arrTemp);

              state.attendingProvider837FilterArr = newArr1;
              state.attendingProviderClaimAgingFilterArr = newArr1;
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = `${item?.name} - ${item?.npi}`;

                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });

              state.attendingProvider837FilterArr = newArr;
              state.attendingProviderClaimAgingFilterArr = newArr;
            }
          } else {
            state.attendingProvider837FilterArr = [];
            state.attendingProviderClaimAgingFilterArr = [];
          }

          state.filterLoader.attendingProvider837 = false;
        } else {
          // console.log("successRenderingProviderFilter__else--->>", data);
          const output = data?.claims;
          if (output?.length) {
            if (data?.selectedAttendingProvidersList?.length > 0) {
              const excludedValues = data?.selectedAttendingProvidersList.map(
                (obj) => obj?.name
              );

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.name)) {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;
                  arrTemp.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                } else {
                  let userFriendlyName = `${obj?.name} - ${obj?.npi}`;

                  newArr1.push({
                    value: obj?.id,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...arrTemp);

              state.attendingProvider837FilterArr = newArr1;
              state.attendingProviderClaimAgingFilterArr = newArr1;
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = `${item?.name}`;

                newArr.push({
                  value: item?.id,
                  label: userFriendlyName,
                });
              });

              state.attendingProvider837FilterArr = newArr;
              state.attendingProviderClaimAgingFilterArr = newArr;
            }
          } else {
            state.attendingProvider837FilterArr = [];
            state.attendingProviderClaimAgingFilterArr = [];
          }

          state.filterLoader.attendingProvider837 = false;
        }
      } catch (err) {
        // console.log("e--->>", err);
      }
    },
    failedRAttendingProviderFilter: (state, action) => {
      state.filterLoader.attendingProvider837 = false;
      state.attendingProvider837FilterArr = [];
    },

    //Provider FILTER
    gettingProviderFilter: (state, action) => {
      state.filterLoader.provider837 = true;
    },
    successProviderFilter: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          // let payeeName_With_PrimaryId = `${item?.name} - ${item?.primaryId}`;
          newArr.push({
            label: item?.name,
            value: item?.billingProviderId,
          });
        });

        state.provider837FilterArr = newArr;
      } else {
        state.provider837FilterArr = [];
      }
      state.filterLoader.provider837 = false;
    },
    failedProviderFilter: (state, action) => {
      state.filterLoader.provider837 = false;
      state.provider837FilterArr = [];
    },

    //FrequencyType FILTER
    gettingFrequencyTypeFilter: (state, action) => {
      state.filterLoader.frequencyType837 = true;
    },
    successFrequecyTypeFilter: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          let userFriendlyName = `${item?.code} - ${item?.description}`;
          newArr.push({
            label: userFriendlyName,
            value: item?.code,
          });
        });

        state.frequencyType837FilterArr = newArr;
      } else {
        state.frequencyType837FilterArr = [];
      }
      state.filterLoader.frequencyType837 = false;
    },
    failedFrequencyTypeFilter: (state, action) => {
      state.filterLoader.frequencyType837 = false;
      state.frequencyType837FilterArr = [];
    },

    //DianoseCode FILTER
    gettingDiagnoseCodeFilter: (state, action) => {
      state.filterLoader.diagnoseCode837 = true;
    },
    successDiagnoseCode2ForSearch: (state, action) => {
      const data = action?.payload;
      // console.log("successPayerFilter2ForSearch--->>", data);
      try {
        if (data?.listingFilter) {
          // console.log("successPayerFilter2ForSearch__if--->>", data);
          const output = data?.data?.claims;
          if (output?.length) {
            if (data?.data?.selectedDiagList?.length > 0) {
              const excludedValues = data?.data?.selectedDiagList.map(
                (obj) => obj?.diagnoseCode
              );
              const excludedArray = data?.data?.selectedDiagList.map((obj) => {
                let userFriendlyName = obj?.diagnosisCodeDesc
                  ? `${obj?.diagnoseCode} - ${obj?.diagnosisCodeDesc}`
                  : `${obj?.diagnoseCode}`;

                return {
                  value: obj?.diagnoseCode,
                  label: userFriendlyName,
                };
              });

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.diagnoseCode)) {
                  arrTemp.push(obj);
                } else {
                  let userFriendlyName = obj?.diagnosisCodeDesc
                    ? `${obj?.diagnoseCode} - ${obj?.diagnosisCodeDesc}`
                    : `${obj?.diagnoseCode}`;
                  newArr1.push({
                    value: obj?.diagnoseCode,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...excludedArray);

              // state.diagnoseCode837FilterArr = newArr1;
              handleMultiFilter(
                state,
                newArr1,
                data?.listingFilter,
                "diagCode"
              );
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = item?.diagnosisCodeDesc
                  ? `${item?.diagnoseCode} - ${item?.diagnosisCodeDesc}`
                  : `${item?.diagnoseCode}`;
                newArr.push({
                  value: item?.diagnoseCode,
                  label: userFriendlyName,
                });
              });

              // state.diagnoseCode837FilterArr = newArr;
              handleMultiFilter(state, newArr, data?.listingFilter, "diagCode");
            }
          } else {
            // state.diagnoseCode837FilterArr = [];
            handleMultiFilter(state, [], data?.listingFilter, "diagCode");
          }

          state.filterLoader.diagnoseCode837 = false;
        } else {
          // console.log("successPayerFilter2ForSearch__else--->>", data);
          const output = data?.claims;
          if (output?.length) {
            if (data?.selectedDiagList?.length > 0) {
              const excludedValues = data?.selectedDiagList.map(
                (obj) => obj?.diagnoseCode
              );
              const excludedArray = data?.selectedDiagList.map((obj) => {
                let userFriendlyName = obj?.diagnosisCodeDesc
                  ? `${obj?.diagnoseCode} - ${obj?.diagnosisCodeDesc}`
                  : `${obj?.diagnoseCode}`;

                return {
                  value: obj?.diagnoseCode,
                  label: userFriendlyName,
                };
              });

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.diagnoseCode)) {
                  arrTemp.push(obj);
                } else {
                  let userFriendlyName = obj?.diagnosisCodeDesc
                    ? `${obj?.diagnoseCode} - ${obj?.diagnosisCodeDesc}`
                    : `${obj?.diagnoseCode}`;
                  newArr1.push({
                    value: obj?.diagnoseCode,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...excludedArray);

              // state.diagnoseCode837FilterArr = newArr1;
              handleMultiFilter(
                state,
                newArr1,
                data?.listingFilter,
                "diagCode"
              );
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = item?.diagnosisCodeDesc
                  ? `${item?.diagnoseCode} - ${item?.diagnosisCodeDesc}`
                  : `${item?.diagnoseCode}`;
                newArr.push({
                  value: item?.diagnoseCode,
                  label: userFriendlyName,
                });
              });

              // state.diagnoseCode837FilterArr = newArr;
              handleMultiFilter(state, newArr, data?.listingFilter, "diagCode");
            }
          } else {
            // state.diagnoseCode837FilterArr = [];
            handleMultiFilter(state, [], data?.listingFilter, "diagCode");
          }

          state.filterLoader.diagnoseCode837 = false;
        }
      } catch (err) {
        // console.log("err--->>", err);
      }
    },
    successDiagnoseCodeFilter: (state, action) => {
      const data = action?.payload;
      // console.log("successDiagnoseCodeFilter--->>", data);
      try {
        if (data?.listingFilter) {
          // console.log("successDiagnoseCodeFilter__if--->>", data);
          const output = data?.data?.claims;
          if (output?.length) {
            if (data?.data?.selectedDiagList?.length > 0) {
              const excludedValues = data?.data?.selectedDiagList.map(
                (obj) => obj?.diagnoseCode
              );
              const excludedArray = data?.data?.selectedDiagList.map((obj) => {
                let userFriendlyName = obj?.diagnosisCodeDesc
                  ? `${obj?.diagnoseCode} - ${obj?.diagnosisCodeDesc}`
                  : `${obj?.diagnoseCode}`;

                return {
                  value: obj?.diagnoseCode,
                  label: userFriendlyName,
                };
              });

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.diagnoseCode)) {
                  arrTemp.push(obj);
                } else {
                  let userFriendlyName = obj?.diagnosisCodeDesc
                    ? `${obj?.diagnoseCode} - ${obj?.diagnosisCodeDesc}`
                    : `${obj?.diagnoseCode}`;
                  newArr1.push({
                    value: obj?.diagnoseCode,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...excludedArray);

              state.diagnoseCode837FilterArr = newArr1;
              state.diagnoseCodeClaimAgingFilterArr = newArr1;
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = item?.diagnosisCodeDesc
                  ? `${item?.diagnoseCode} - ${item?.diagnosisCodeDesc}`
                  : `${item?.diagnoseCode}`;
                newArr.push({
                  value: item?.diagnoseCode,
                  label: userFriendlyName,
                });
              });

              state.diagnoseCode837FilterArr = newArr;
              state.diagnoseCodeClaimAgingFilterArr = newArr;
            }
          } else {
            state.diagnoseCode837FilterArr = [];
            state.diagnoseCodeClaimAgingFilterArr = [];
          }

          state.filterLoader.diagnoseCode837 = false;
        } else {
          // console.log("successDiagnoseCodeFilter__else--->>", data);
          const output = data?.claims;
          if (output?.length) {
            if (data?.selectedDiagList?.length > 0) {
              const excludedValues = data?.selectedDiagList.map(
                (obj) => obj?.diagnoseCode
              );
              const excludedArray = data?.selectedDiagList.map((obj) => {
                let userFriendlyName = obj?.diagnosisCodeDesc
                  ? `${obj?.diagnoseCode} - ${obj?.diagnosisCodeDesc}`
                  : `${obj?.diagnoseCode}`;
                return {
                  value: obj?.diagnoseCode,
                  label: userFriendlyName,
                };
              });

              let arrTemp = [];
              let newArr1 = [];
              output?.filter((obj) => {
                if (excludedValues.includes(obj?.diagnoseCode)) {
                  arrTemp.push(obj);
                } else {
                  let userFriendlyName = obj?.diagnosisCodeDesc
                    ? `${obj?.diagnoseCode} - ${obj?.diagnosisCodeDesc}`
                    : `${obj?.diagnoseCode}`;
                  newArr1.push({
                    value: obj?.diagnoseCode,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...excludedArray);

              state.diagnoseCode837FilterArr = newArr1;
              state.diagnoseCodeClaimAgingFilterArr = newArr1;
            } else {
              let newArr = [];

              output?.forEach((item) => {
                let userFriendlyName = item?.diagnosisCodeDesc
                  ? `${item?.diagnoseCode} - ${item?.diagnosisCodeDesc}`
                  : `${item?.diagnoseCode}`;
                newArr.push({
                  value: item?.diagnoseCode,
                  label: userFriendlyName,
                });
              });

              state.diagnoseCode837FilterArr = newArr;
              state.diagnoseCodeClaimAgingFilterArr = newArr;
            }
          } else {
            state.diagnoseCode837FilterArr = [];
            state.diagnoseCodeClaimAgingFilterArr = [];
          }

          state.filterLoader.diagnoseCode837 = false;
        }
      } catch (err) {
        // console.log("e--->>", err);
      }
    },
    failedDiagnoseCodeFilter: (state, action) => {
      state.filterLoader.diagnoseCode837 = false;
      state.diagnoseCode837FilterArr = [];
    },

    //CPT CODES
    gettingCptCodeFilter: (state, action) => {
      state.filterLoader.cptCode837 = true;
    },
    successCptCodeFilter: (state, action) => {
      const data = action?.payload;
      // console.log("successCptCodeFilter--->>", data);
      if (data?.listingFilter) {
        // console.log("successCptCodeFilter__if--->>", data);
        const output = data?.data?.cptList;
        if (output?.length) {
          if (data?.data?.selectedCptList?.length > 0) {
            const excludedValues = data?.data?.selectedCptList.map(
              (obj) => obj?.cptCode
            );
            const excludedArray = data?.data?.selectedCptList.map((obj) => {
              let userFriendlyName = obj?.description
                ? `${obj?.cptCode} - ${obj?.description}`
                : `${obj?.cptCode}`;

              return {
                value: obj?.cptCode,
                label: userFriendlyName,
              };
            });

            let arrTemp = [];
            let newArr1 = [];
            output?.filter((obj) => {
              if (excludedValues.includes(obj?.cptCode)) {
                arrTemp.push(obj);
              } else {
                let userFriendlyName = obj?.description
                  ? `${obj?.cptCode} - ${obj?.description}`
                  : `${obj?.cptCode}`;
                newArr1.push({
                  value: obj?.cptCode,
                  label: userFriendlyName,
                });
              }
            });

            newArr1.unshift(...excludedArray);

            state.cptCode837FilterArr = newArr1;
            state.cptCodeClaimAgingFilterArr = newArr1;
          } else {
            let newArr = [];

            output?.forEach((item) => {
              let userFriendlyName = item?.description
                ? `${item?.cptCode} - ${item?.description}`
                : `${item?.cptCode}`;
              newArr.push({
                value: item?.cptCode,
                label: userFriendlyName,
              });
            });

            state.cptCode837FilterArr = newArr;
            state.cptCodeClaimAgingFilterArr = newArr;
          }
        } else {
          state.cptCode837FilterArr = [];
          state.cptCodeClaimAgingFilterArr = [];
        }

        state.filterLoader.cptCode837 = false;
      } else {
        // console.log("successCptCodeFilter__else--->>", data);
        const output = data?.cptList;
        if (output?.length) {
          if (data?.selectedCptList?.length > 0) {
            const excludedValues = data?.selectedCptList.map(
              (obj) => obj?.cptCode
            );
            const excludedArray = data?.selectedCptList.map((obj) => {
              let userFriendlyName = obj?.description
                ? `${obj?.cptCode} - ${obj?.description}`
                : `${obj?.cptCode}`;

              return {
                value: obj?.cptCode,
                label: userFriendlyName,
              };
            });

            let arrTemp = [];
            let newArr1 = [];
            output?.filter((obj) => {
              if (excludedValues.includes(obj?.cptCode)) {
                arrTemp.push(obj);
              } else {
                let userFriendlyName = obj?.description
                  ? `${obj?.cptCode} - ${obj?.description}`
                  : `${obj?.cptCode}`;
                newArr1.push({
                  value: obj?.cptCode,
                  label: userFriendlyName,
                });
              }
            });

            newArr1.unshift(...excludedArray);

            state.cptCode837FilterArr = newArr1;
            state.cptCodeClaimAgingFilterArr = newArr1;
          } else {
            let newArr = [];

            output?.forEach((item) => {
              let userFriendlyName = item?.description
                ? `${item?.cptCode} - ${item?.description}`
                : `${item?.cptCode}`;
              newArr.push({
                value: item?.cptCode,
                label: userFriendlyName,
              });
            });

            state.cptCode837FilterArr = newArr;
            state.cptCodeClaimAgingFilterArr = newArr;
          }
        } else {
          state.cptCode837FilterArr = [];
          state.cptCodeClaimAgingFilterArr = [];
        }

        state.filterLoader.cptCode837 = false;
      }
    },
    successCptCodeFilter2: (state, action) => {
      const data = action?.payload;
      // console.log("successCptCodeFilter2_--->>", data);

      try {
        if (data?.listingFilter) {
          let finalArr = [];
          let data1 = data?.data;
          // console.log("successCptCodeFilter2_if--->>", data1);

          if (data1?.cptList?.length) {
            if (data1?.selectedCptList?.length > 0) {
              const excludedValues = data1?.selectedCptList.map(
                (obj) => obj?.cptCode
              );

              const excludedArray = data1?.selectedCptList.map((obj) => {
                let userFriendlyName = obj?.description
                  ? `${obj?.cptCode} - ${obj?.description}`
                  : `${obj?.cptCode}`;

                return {
                  value: obj?.cptCode,
                  label: userFriendlyName,
                };
              });

              let arrTemp = [];
              let newArr1 = [];
              data1?.cptList?.filter((obj) => {
                let userFriendlyName = obj?.description
                  ? `${obj?.cptCode} - ${obj?.description}`
                  : `${obj?.cptCode}`;
                if (excludedValues.includes(obj?.cptCode)) {
                  arrTemp.push({
                    value: obj?.cptCode,
                    label: userFriendlyName,
                  });
                } else {
                  newArr1.push({
                    value: obj?.cptCode,
                    label: userFriendlyName,
                  });
                }
              });

              newArr1.unshift(...(arrTemp?.length ? arrTemp : excludedArray));

              finalArr = newArr1;

              // state.cptCode837FilterArr = finalArr;
              handleMultiFilter(
                state,
                finalArr,
                data?.listingFilter,
                "cptCode"
              );
              state.filterLoader.cptCode837 = false;
            } else {
              let newArr = [];

              data1?.cptList?.forEach((item) => {
                let userFriendlyName = item?.description
                  ? `${item?.cptCode} - ${item?.description}`
                  : `${item?.cptCode}`;
                newArr.push({
                  value: item?.cptCode,
                  label: userFriendlyName,
                });
              });

              finalArr = newArr;

              // state.cptCode837FilterArr = finalArr;
              handleMultiFilter(
                state,
                finalArr,
                data?.listingFilter,
                "cptCode"
              );
              state.filterLoader.cptCode837 = false;
            }
          } else {
            finalArr = [];

            state.cptCode837FilterArr = finalArr;
            handleMultiFilter(state, finalArr, data?.listingFilter, "cptCode");
            state.filterLoader.cptCode837 = false;
          }
        } else {
          // console.log("successCptCodeFilter2_else--->>", data);
        }
      } catch (e) {
        // console.log("successCptCodeFilter2_e--->>", e);
      }
    },
    failedCptCodeFilter: (state, action) => {
      state.filterLoader.cptCode837 = false;
      state.cptCode837FilterArr = [];
    },
  },
});

export const {
  //Service Line Filters
  gettingServiceLineFilter,
  successServiceLineFilter,
  failedServiceLineFilter,

  //Payers Filters
  gettingPayerFilter,
  failedPayerFilter,
  successPayerFilter,
  successPayerFilter2ForSearch,

  //Filling Indicator
  gettingFillingIndicatorFilter,
  successFillingIndicatorFilter,
  failedFillingIndicatorFilter,

  //BillingProvider
  gettingBillingProviderFilter,
  successBillingProvider2ForSearch,
  successBillingProviderFilter,
  failedBillingProviderFilter,

  //RenderingProvider
  gettingRenderingProviderFilter,
  successRenderingProvider2ForSearch,
  successRenderingProviderFilter,
  failedRenderingProviderFilter,

  //AttendingProvider
  gettingAttendingProviderFilter,
  successAttendingProvider2ForSearch,
  successAttendingProviderFilter,
  failedAttendingProviderFilter,

  //Provider FILTER
  gettingProviderFilter,
  successProviderFilter,
  failedProviderFilter,

  //FrequencyType FILTER
  gettingFrequencyTypeFilter,
  successFrequecyTypeFilter,
  failedFrequencyTypeFilter,

  //DianoseCode FILTER
  gettingDiagnoseCodeFilter,
  successDiagnoseCodeFilter,
  successDiagnoseCode2ForSearch,
  failedDiagnoseCodeFilter,

  //CPT Code
  gettingCptCodeFilter,
  successCptCodeFilter,
  successCptCodeFilter2,
  failedCptCodeFilter,
} = claim837AllFiltersSlice.actions;

export const getClaimAll837FiltersData_Call =
  (
    cptParams,
    payersParams,
    diagnosisParams,
    billingProviderParams,
    renderingProviderParams,
    attendingProviderParams,
    {
      isServiceLineAPI,
      isPayersAPI,
      isFillingIndicatorAPI,
      isBillingProviderAPI,
      isRenderingProviderAPI,
      isAttendingProviderAPI,
      isProviderAPI,
      isFrequencyAPI,
      isDiagCodeAPI,
      isCptAPI,
    }
  ) =>
  async (dispatch, getState) => {
    try {
      // //ServiceLine Filters
      {
        isServiceLineAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: {},
              params: {},
              dispatch: dispatch,
              stateValueGetter: gettingServiceLineFilter,
              stateValueSetter: successServiceLineFilter,
              stateValueFailed: failedServiceLineFilter,
              listingAction: "claimRequestServiceLineFilter",
              apiURL: "CLAIM_REQUEST_SERVICELINE_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      // //Payer Filters
      {
        isPayersAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: payersParams,
              params: payersParams, // ---- payersParams
              dispatch: dispatch,
              stateValueGetter: gettingPayerFilter,
              stateValueSetter: successPayerFilter,
              stateValueFailed: failedPayerFilter,
              listingAction: "claimRequestPayerFilter",
              apiURL: "CLAIM_REQUEST_PAYERS_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      //FILLING INDICATOR
      {
        isFillingIndicatorAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: {},
              params: {},
              dispatch: dispatch,
              stateValueGetter: gettingFillingIndicatorFilter,
              stateValueSetter: successFillingIndicatorFilter,
              stateValueFailed: failedFillingIndicatorFilter,
              listingAction: "claimRequestFillingIndicatorFilter",
              apiURL: "CLAIM_REQUEST_FILLING_INDICATOR_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      //BillingProvider
      {
        isBillingProviderAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: billingProviderParams,
              params: billingProviderParams, // ---- Params
              dispatch: dispatch,
              stateValueGetter: gettingBillingProviderFilter,
              stateValueSetter: successBillingProviderFilter,
              stateValueFailed: failedBillingProviderFilter,
              listingAction: "claimRequestBillingProviderFilter",
              apiURL: "CLAIM_REQUEST_BILLING_PROVIDER_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      //RenderingProvider
      {
        isRenderingProviderAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: renderingProviderParams,
              params: renderingProviderParams, // ---- Params
              dispatch: dispatch,
              stateValueGetter: gettingRenderingProviderFilter,
              stateValueSetter: successRenderingProviderFilter,
              stateValueFailed: failedRenderingProviderFilter,
              listingAction: "claimRequestRenderingProviderFilter",
              apiURL: "CLAIM_REQUEST_RENDERING_PROVIDER_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }
      //AttendingProvider
      {
        isAttendingProviderAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: attendingProviderParams,
              params: attendingProviderParams, // ---- Params
              dispatch: dispatch,
              stateValueGetter: gettingAttendingProviderFilter,
              stateValueSetter: successAttendingProviderFilter,
              stateValueFailed: failedAttendingProviderFilter,
              listingAction: "claimRequestAttendingProviderFilter",
              apiURL: "CLAIM_REQUEST_ATTENDING_PROVIDER_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      //Provider
      {
        isProviderAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: {},
              params: {},
              dispatch: dispatch,
              stateValueGetter: gettingProviderFilter,
              stateValueSetter: successProviderFilter,
              stateValueFailed: failedProviderFilter,
              listingAction: "claimRequestProviderFilter",
              apiURL: "CLAIM_REQUEST_PROVIDERS_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      // Frequency Type
      {
        isFrequencyAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: {},
              params: {},
              dispatch: dispatch,
              stateValueGetter: gettingFrequencyTypeFilter,
              stateValueSetter: successFrequecyTypeFilter,
              stateValueFailed: failedFrequencyTypeFilter,
              listingAction: "claimRequestFrequencyTypeFilter",
              apiURL: "CLAIM_REQUEST_FREQUENCY_TYPE_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      // Diagnose Code
      {
        isDiagCodeAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: diagnosisParams,
              params: diagnosisParams,
              dispatch: dispatch,
              stateValueGetter: gettingDiagnoseCodeFilter,
              stateValueSetter: successDiagnoseCodeFilter,
              stateValueFailed: failedDiagnoseCodeFilter,
              listingAction: "claimRequestDiagnoseCodeFilter",
              apiURL: "CLAIM_REQUEST_DIAGNOSE_CODES_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }

      //CPT Code
      {
        isCptAPI == true
          ? commonPaginationWithCatch({
              getState: getState,
              paramsTemp: cptParams,
              params: cptParams, // ---- cptParams
              dispatch: dispatch,
              stateValueGetter: gettingCptCodeFilter,
              stateValueSetter: successCptCodeFilter,
              stateValueFailed: failedCptCodeFilter,
              listingAction: "claimRequestCptCodesFilter",
              apiURL: "CLAIM_REQUEST_CPT_CODES_FILTER",
              conditionalDispatch: false,
              conditionalApiURL: "",
              conditionalStateValueSetter: "",
            })
          : null;
      }
    } catch (e) {
      // console.log("getClaimAll837FiltersData_Call_err --->>", e);

      return false;
    }
  };

// common search handler start here ===============================

const createCommonCustomSearchHandler = (
  filterKey,
  filterKeyword,
  apiRoute,
  dispatchSuccessAction,
  dispatchSuccessAction2,
  dispatchFailureAction,
  dispatchFailureAction2
) => {
  // console.log("createCommonCustomSearchHandler--->>");
  return (searchVal, setSearchLoading, listingFilter) =>
    async (dispatch, getState) => {
      const sliceState = getState().npReducers.filters[listingFilter];

      // Define an array of listing filters where you want to apply a common filter
      const filtersWithCommonFilter = [
        "claimManager",

        // "serviceLineDenialsReportFilter",
      ];

      // Determine the filter key based on the listing filter type
      const listingFilterKey = filtersWithCommonFilter.includes(listingFilter)
        ? "insightsSreensCommonFilter"
          ? "denialSreensCommonFilter"
          : listingFilter === "claimManager"
        : listingFilter;

      // Get the slice state for the selected filter
      const sliceStateForDate = getState().npReducers.filters[listingFilterKey];

      try {
        const filterParams = {
          ...(sliceStateForDate?.from !== "" && {
            from: sliceStateForDate?.from,
          }),
          ...(sliceStateForDate?.to !== "" && {
            to: sliceStateForDate?.to,
          }),
          [filterKeyword]: searchVal ? searchVal : "",
          start: 0,
          limit: 100,
          ...(sliceState?.[filterKey]?.length > 0 && {
            [`selected${
              filterKey.charAt(0).toUpperCase() + filterKey.slice(1)
            }`]: getMultiSelectFilterValueForApi(sliceState?.[filterKey]),
          }),
        };

        // console.log("filterParams--->>", filterParams);

        const res = await genericAxiosCall(apiRoute, "get", "", filterParams)
          .then((response) => {
            if (response?.data?.success) {
              const data = {
                data: response?.data?.data,
                listingFilter,
              };

              if (searchVal === "") {
                dispatch(dispatchSuccessAction(data));
              } else {
                dispatch(dispatchSuccessAction2(data));
              }

              setSearchLoading(false);
              return data;
            } else {
              if (searchVal === "") {
                dispatch(dispatchFailureAction([]));
              } else {
                dispatch(dispatchFailureAction2([]));
              }
            }
          })
          .catch((error) => {
            if (searchVal === "") {
              dispatch(dispatchFailureAction([]));
            } else {
              dispatch(dispatchFailureAction2([]));
            }
          });

        return res;
      } catch (err) {
        // console.log("catch_cerr--->>", err);
        // Handle errors if needed
      }
    };
};

export const commonCustomSearch837CPTHandler = createCommonCustomSearchHandler(
  "cptCodes",
  "CPTCode",
  serverRoutes.CLAIM_REQUEST_CPT_CODES_FILTER,
  successCptCodeFilter,
  successCptCodeFilter2,
  successCptCodeFilter,
  successCptCodeFilter2
);

export const commonCustomSearch837DiagCodeHandler =
  createCommonCustomSearchHandler(
    "DiagnosisCode",
    "DiagnosisCode",
    serverRoutes.CLAIM_REQUEST_DIAGNOSE_CODES_FILTER,
    successDiagnoseCodeFilter,
    successDiagnoseCode2ForSearch,
    successDiagnoseCodeFilter,
    successDiagnoseCode2ForSearch
  );

export const commonCustomSearch837BillingProviderHandler =
  createCommonCustomSearchHandler(
    "billingProviders",
    "searchKeyword",
    serverRoutes.CLAIM_REQUEST_BILLING_PROVIDER_FILTER,
    successBillingProviderFilter,
    successBillingProvider2ForSearch,
    successBillingProviderFilter,
    successBillingProvider2ForSearch
  );

export const commonCustomSearch837RenderingProviderHandler =
  createCommonCustomSearchHandler(
    "renderingProviders",
    "searchKeyword",
    serverRoutes.CLAIM_REQUEST_RENDERING_PROVIDER_FILTER,
    successRenderingProviderFilter,
    successRenderingProvider2ForSearch,
    successRenderingProviderFilter,
    successRenderingProvider2ForSearch
  );

export const commonCustomSearch837AttendingProviderHandler =
  createCommonCustomSearchHandler(
    "attendingProviders",
    "searchKeyword",
    serverRoutes.CLAIM_REQUEST_ATTENDING_PROVIDER_FILTER,
    successAttendingProviderFilter,
    successAttendingProvider2ForSearch,
    successAttendingProviderFilter,
    successAttendingProvider2ForSearch
  );

export const commonCustomSearch837PayersHandler =
  createCommonCustomSearchHandler(
    "payers",
    "searchKeyword",
    serverRoutes.CLAIM_REQUEST_PAYERS_FILTER,
    successPayerFilter,
    successPayerFilter2ForSearch,
    successPayerFilter,
    successPayerFilter2ForSearch
  );

// common search handler end here ===============================

// hanlder filters switch statement
function handleMultiFilter(state, arr, listingFilter, filterType) {
  // console.log("handleMultiFilter--->>", listingFilter, filterType, arr);

  switch (listingFilter) {
    case "claimAgingReportFilter": // #01 ClaimAging screen
      if (filterType == "payers") {
        state.payersClaimAgingFilterArr = arr;
        state.filterLoader.payers837 = false;
      } else if (filterType == "diagCode") {
        state.diagnoseCodeClaimAgingFilterArr = arr;
        state.filterLoader.diagnoseCode837 = false;
      } else if (filterType == "cptCode") {
        state.cptCodeClaimAgingFilterArr = arr;
        state.filterLoader.cptCode837 = false;
      } else if (filterType == "billingProvider") {
        state.billingProviderClaimAgingFilteArr = arr;
        state.filterLoader.billingProvider837 = false;
      } else if (filterType == "renderingProvider") {
        state.renderingProviderClaimAgingFilterArr = arr;
        state.filterLoader.renderingProvider837 = false;
      } else if (filterType == "attendingProvider") {
        state.attendingProviderClaimAgingFilterArr = arr;
        state.filterLoader.attendingProvider837 = false;
      }

      break;

    case "claimManager": // #02 Claims (837) screen
      if (filterType == "payers") {
        state.payers837FilterArr = arr;
        state.filterLoader.payers837 = false;
      } else if (filterType == "diagCode") {
        state.diagnoseCode837FilterArr = arr;
        state.filterLoader.diagnoseCode837 = false;
      } else if (filterType == "billingProvider") {
        state.billingProvider837FilterArr = arr;
        state.filterLoader.billingProvider837 = false;
      } else if (filterType == "renderingProvider") {
        state.renderingProvider837FilterArr = arr;
        state.filterLoader.renderingProvider837 = false;
      } else if (filterType == "attendingProvider") {
        state.attendingProvider837FilterArr = arr;
        state.filterLoader.attendingProvider837 = false;
      } else if (filterType == "cptCode") {
        state.cptCode837FilterArr = arr;
        state.filterLoader.cptCode837 = false;
      }

      break;

    default:
      // Claims screen
      state.payers837FilterArr = arr;
      state.diagnoseCode837FilterArr = arr;
      state.billingProvider837FilterArr = arr;
      state.renderingProvider837FilterArr = arr;
      state.attendingProvider837FilterArr = arr;
      state.cptCode837FilterArr = arr;

      // ClaimAging screen
      state.payersClaimAgingFilterArr = arr;
      state.diagnoseCodeClaimAgingFilterArr = arr;
      state.cptCodeClaimAgingFilterArr = arr;

      state.billingProviderClaimAgingFilteArr = arr;
      state.renderingProviderClaimAgingFilterArr = arr;
      state.attendingProviderClaimAgingFilterArr = arr;

      // filters loading
      state.filterLoader.diagnoseCode837 = false;
      state.filterLoader.payers837 = false;
      state.filterLoader.billingProvider837 = false;
      state.filterLoader.renderingProvider837 = false;
      state.filterLoader.attendingProvider837 = false;
      state.filterLoader.cptCode837 = false;

      break;
  }
}

export default claim837AllFiltersSlice.reducer;
