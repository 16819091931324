import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userName: "",
  subscriptionID: "",
  domainID: "",
  domainName: "",
  domainRealName: "",
  loginUserData: {},
  userToken: "",
  userRoles: "",
  permission: "",
  userPreferences: {},
  loginTime: "",
  loginTimeWithDate: "",
  sessionTimeOut: "",
  code: "",
  claimPaymentColumn: {},
  underPaymentColumn: {},
  drugDenialDetailClm1: {},
  OncologyAccDet: {},
  workQueuePageColumn: {},
  alertCenterPageColumn: {},
  organizationName: "",
  denialByClaimsPageColumnSetting: {},
  claimAjdGBClaimPageColumnSetting: {},
  claimAjdGBServiceLinePageColumnSetting: {},
  partiallyDeniedPageColumnSetting: {},
  reversalPageColumnSetting: {},
  claimManager: {},
  modulePricing: {},
  moduleUsage: {},
  applicationToken: "",
  applicationTokenExpiry: "",
  drawerState: false,
  subLeftMenu: "",
  menuStyle: "LeftMenu",
  insightsDropDown: false,
  denialDropDown: false,
  claimsDropDown: false,
  reportsDropDown: false,
  rememberMe: false,
  confirmCode_screen: false,
  azureLoading: false,
  confirmCode_screen_info: {},
  moduleRoles: [],
  advStngServiceLineExecList: "",
  loginType: "",
  masterListCodes: {},
  masterListReasonCodes: {},
  denialCoordinatorsList: [],
  CoordinatorPreference: {},
  allSubscriptionModules: [],
  clientId: "",
  allModulesObj: {},
  currentModule: {
    label: "Denials 360",
    value: "Denials",
  },
};

export const UserReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    setClientIDSettings(state, action) {
      return { ...state, clientId: action.payload };
    },
    setdenialByClaimsPageColumnSetting(state, action) {
      return { ...state, denialByClaimsPageColumnSetting: action.payload };
    },

    setClaimAjdGBClaimPageColumnSetting(state, action) {
      return { ...state, claimAjdGBClaimPageColumnSetting: action.payload };
    },
    setAllSubscriptionModules(state, action) {
      return {
        ...state,
        allSubscriptionModules: action.payload?.allSubscriptionModules,
        allModulesObj: action.payload?.allModulesObj,
      };
    },
    setClaimAjdGBServiceLinePageColumnSetting(state, action) {
      return {
        ...state,
        claimAjdGBServiceLinePageColumnSetting: action.payload,
      };
    },
    setPartiallyDeniedPageColumnSetting(state, action) {
      return {
        ...state,
        partiallyDeniedPageColumnSetting: action.payload,
      };
    },
    setReversalPageColumnSetting(state, action) {
      return {
        ...state,
        reversalPageColumnSetting: action.payload,
      };
    },
    updateApplicationToken(state, action) {
      return { ...state, applicationToken: action.payload };
    },
    updateApplicationTokenExpiry(state, action) {
      try {
        const { applicationToken, applicationTokenExpiry } = action?.payload;
        state.applicationToken = applicationToken;
        state.applicationTokenExpiry = applicationTokenExpiry;
      } catch (error) {}
    },

    updateMemberMe(state, action) {
      return { ...state, rememberMe: action.payload };
    },
    setConfirmCode_Screen(state, action) {
      return { ...state, confirmCode_screen: action.payload };
    },
    setConfirmCode_Screen_info(state, action) {
      return { ...state, confirmCode_screen_info: action.payload };
    },
    setPreferences(state, action) {
      state = { ...state, userPreferences: action.payload };
      return state;
    },
    setOrganization(state, action) {
      state = { ...state, organizationName: action.payload };
      return state;
    },

    userData(state, action) {
      const { payload } = action;

      state = {
        ...state,
        userName: payload.userName,
        subscriptionID: payload.subscriptionID,
        domainID: payload.domainID,
        loginUserData: payload.loginUserData,
        userToken: payload.userToken,
        applicationToken: payload.applicationToken,
        userRoles: payload.userRoles,
        permission: payload.permission,
        organizationName: payload?.organizationName,
        code: payload.code,
        loginType: payload?.loginType,
        applicationTokenExpiry: payload?.applicationTokenExpiry,
        domainName: payload?.domainName,
        domainRealName: payload?.domainRealName,
      };
      return state;
    },
    logoutUser(state, action) {
      return { ...state, ...initialState };
    },
    setAzureLoading(state, action) {
      return { ...state, azureLoading: action.payload };
    },
    ClaimMng837ColumnsSetting(state, action) {
      const { payload } = action;
      state = {
        ...state,
        loginUserData: payload.loginUserData,
      };
      return state;
    },
    updateUserName(state, action) {
      return { ...state, userName: action.payload };
    },
    updateSubscriptionId(state, action) {
      return { ...state, subscriptionID: action.payload };
    },
    updateDomainId(state, action) {
      return { ...state, domainID: action.payload };
    },
    updateBoughtModules(state, action) {
      let loginUserDataTemp = {
        ...state.loginUserData,
        modules: action?.payload,
      };

      return { ...state, loginUserData: loginUserDataTemp };
    },
    updateModuleUsage(state, action) {
      return { ...state, moduleUsage: action?.payload };
    },
    updateModulePricing(state, action) {
      return { ...state, modulePricing: action?.payload };
    },
    updateUserModuleInfo(state, action) {
      let loginUserDataTemp = {
        ...state.loginUserData,
        selectedModules: action?.payload,
      };

      return { ...state, loginUserData: loginUserDataTemp };
    },
    updateDrawerState(state, action) {
      return { ...state, drawerState: action.payload };
    },
    updateSubLeftMenu(state, action) {
      return { ...state, subLeftMenu: action.payload };
    },
    updateMenuStyle(state, action) {
      return { ...state, menuStyle: action.payload };
    },
    updateInsightsDropDown(state, action) {
      return { ...state, insightsDropDown: action.payload };
    },
    updateDenialDropDown(state, action) {
      return { ...state, denialDropDown: action.payload };
    },
    updateClaimsDropDown(state, action) {
      return {
        ...state,
        claimsDropDown: action.payload,
        denialDropDown: false,
        reportsDropDown: false,
        insightsDropDown: false,
      };
    },
    updateMasterListCodes(state, action) {
      return {
        ...state,
        masterListCodes: action?.payload,
      };
    },
    updateMasterListReasonCodes(state, action) {
      return {
        ...state,
        masterListReasonCodes: action?.payload,
      };
    },
    updateReportsDropDown(state, action) {
      return {
        ...state,
        reportsDropDown: action.payload,
        claimsDropDown: false,
        denialDropDown: false,
        insightsDropDown: false,
      };
    },
    setClaimManger(state, action) {
      state = { ...state, claimManager: action.payload };
      return state;
    },
    setClaimPayment(state, action) {
      state = { ...state, claimPaymentColumn: action.payload };
      return state;
    },
    setUnderPayment(state, action) {
      state = { ...state, underPaymentColumn: action.payload };
      return state;
    },
    setDrugDenialDetailClm1(state, action) {
      state = { ...state, drugDenialDetailClm1: action.payload };
      return state;
    },
    setColumnInitialiserDrugDetail(state, action) {
      let column = { column: action?.payload };
      state = { ...state, drugDenialDetailClm1: column };
      return state;
    },
    setColumnInitialiser(state, action) {
      let payload = action?.payload;
      const column = payload?.column;
      const target = payload?.target;
      state = { ...state, [target]: column };
      return state;
    },
    setOncologyAccDet(state, action) {
      state = { ...state, OncologyAccDet: action.payload };
      return state;
    },
    setWorkQueuePageColumn(state, action) {
      state = { ...state, workQueuePageColumn: action.payload };
      return state;
    },
    setAlertCenterPageColumn(state, action) {
      state = { ...state, alertCenterPageColumn: action.payload };
      return state;
    },
    setLoginTime(state, action) {
      let date = new Date();
      //1 hour 20 mins
      var timeout = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours() + 1,
        date.getMinutes() + 20,
        0
      );
      state = {
        ...state,
        loginTime: action.payload,
        loginTimeWithDate: new Date(),
        sessionTimeOut: timeout,
      };

      return state;
    },
    updateLoginUserData(state, action) {
      return { ...state, loginUserData: action.payload };
    },
    updateUserToken(state, action) {
      return { ...state, userToken: action.payload };
    },
    setDenialCoordinatorList(state, action) {
      return { ...state, denialCoordinatorsList: action.payload };
    },
    setCurrentModuleRedux(state, action) {
      return { ...state, currentModule: action.payload };
    },

    setAdvStngServiceLineExcepList(state, action) {
      try {
        state.advStngServiceLineExecList = action.payload;
      } catch (e) {
        console.log("catch--->>", e);
      }
    },
  },
});

export const {
  setClientIDSettings,
  userData,
  setOrganization,
  logoutUser,
  setClaimManger,
  setClaimPayment,
  setUnderPayment,
  setDrugDenialDetailClm1,
  setColumnInitialiserDrugDetail,
  setColumnInitialiser,
  setOncologyAccDet,
  setAlertCenterPageColumn,
  setWorkQueuePageColumn,
  setPreferences,
  setLoginTime,
  setAzureLoading,
  setdenialByClaimsPageColumnSetting,
  setClaimAjdGBClaimPageColumnSetting,
  setClaimAjdGBServiceLinePageColumnSetting,
  setPartiallyDeniedPageColumnSetting,
  updateBoughtModules,
  updateUserModuleInfo,
  updateModuleUsage,
  updateModulePricing,
  setReversalPageColumnSetting,
  setAllSubscriptionModules,
  updateApplicationToken,
  updateApplicationTokenExpiry,
  updateInsightsDropDown,
  updateDrawerState,
  updateSubLeftMenu,
  updateMenuStyle,
  updateDenialDropDown,
  updateReportsDropDown,
  updateSubscriptionId,
  setCurrentModuleRedux,
  updateDomainId,
  updateUserName,
  updateLoginUserData,
  updateUserToken,
  updateMemberMe,
  setConfirmCode_Screen,
  setConfirmCode_Screen_info,
  updateClaimsDropDown,
  ClaimMng837ColumnsSetting,
  setAdvStngServiceLineExcepList,
  updateMasterListCodes,
  updateMasterListReasonCodes,
  setDenialCoordinatorList,
} = UserReducer.actions;
export default UserReducer.reducer;
